header {
    background-color: $white;
    border-bottom: 1px solid $gray-quaternary;
    box-sizing: border-box;
    z-index: 20;
    @include position(fixed, 0 null null 0);
    @include size(100% 70px);

    .header__icon {
        @include size(136px 27px); }

    .header__logo-slogan {
        @include size(160px 45px);

        @include grid-media($desktop) {
            @include size(195px 55px); } }

    .container {
        height: 100%; }

    .header__title {
        font-size: 1em;
        font-weight: 600;
        margin: 0; }

    .action {
        min-width: 40px;

        .arrival-button {
            fill: $red;
            animation: bounce 1s infinite normal;

            @keyframes bounce {
                0% {
                    transform: translateY(0); }

                50% {
                    transform: translateY(-3px); }

                100% {
                    transform: translateY(0); } } } }

    &.header-gray {
        background-color: $gray-primary;
        border: none;
        color:  $gray-tertiary; }

    nav {
        font-size: .875em;

        &.nav_no-hidden {
            .link,
            .btn {
                display: inline-block; } }

        .link,
        .btn,
        .icon-btn {
            display: none;
            margin-left: 1.5rem;
            vertical-align: middle;
            cursor: pointer;

            @include grid-media($desktop) {
                display: inline-block; } }

        .link {
            text-decoration: none;
            transition: font-weight .4s ease-in-out, color .4s ease-in-out;

            &:hover {
                color: $red; } }

        .btn {
            font-size: .875em;
            width: auto; }

        .icon-btn {
            fill: $gray-primary;
            transition: 0.4s ease-in-out;
            @include size(30px);

            &:hover {
                fill: $red; } } }

    .btn-menu-container {
        display: block;

        @include grid-media($desktop) {
            display: none; } } }

.inner-header {
    padding: 2em 0;
    text-align: center;

    @include grid-media($mobile) {
        padding: 3em 0; }

    .main-title {
        font-size: 1.25em;
        font-weight: 700;

        @include grid-media($tablet) {
            font-size: 1.5em; } }

    .sub-title {
        font-size: 1em;
        font-weight: 400;
        line-height: 1.5em; } }

.registration-header {
    font-size: 1em;
    line-height: 1.5em;
    padding: 2em 1em;
    text-align: center;

    @include grid-media($tablet) {
        padding: 2em 5em; } }

.operator-header {
    .icon-btn {
        fill: $gray-tertiary; }

    .link {
        font-size: 1rem;

        a {
            text-decoration: none; }

        &_active {
            color: $white; }

        button {
            color: $gray-tertiary; } } }

.btn-filter-container {
    box-sizing: border-box;
    cursor: pointer;
    @include padding(5px);
    @include size(40px);

    .icon-btn {
        fill: $red;
        @include size(30px); } }
