a {
    color: inherit;
    transition: color .4s ease-in-out; }

.link {
    background-color: transparent;
    border: none;
    outline: none;
    transition: color .4s ease-in-out;

    &_red {
        color: $red;

        &:hover {
            color: $gray-primary; } }

    &_gray {
        color: $gray-primary;

        &:hover {
            color: $red; } }

    &_white {
        color: $white;

        &:hover {
            color: $red; } }

    &_green {
        color: $white;

        &:hover {
            color: $green; } } }

.btn {
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    padding: 1.1875rem .9375rem;
    text-align: center;
    text-decoration: none;
    transition: color .4s ease-in-out, background-color 0.4s ease-in-out, border-color .4s ease-in-out;
    vertical-align: middle;
    width: 100%;
    @include border-bottom-radius(5px);
    @include border-top-radius(5px);

    &_light-gray {
        background-color: $gray-tertiary;
        color: $white;

        &:hover {
            background-color: $gray-primary; } }

    &_gray {
        background-color: $gray-primary;
        color: $white;

        &:hover {
            background-color: $red; } }

    &_red {
        background-color: $red;
        color: $white;

        &:hover {
            background-color: $gray-primary; } }

    &_sm {
        @include padding(.6875rem .9375rem); }

    &_sm-wide {
        width: 10em; }

    &_outline {
        background-color: transparent;
        border-radius: 5px;
        border: 1px solid $white;

        &:hover {
            background-color: $white;
            color: $red; } }

    &_gray-outline {
        background-color: transparent;
        border-radius: 5px;
        border: 1px solid $gray-primary;

        &:hover {
            background-color: $gray-primary;
            color: $white; } }

    &_red-outline {
        background-color: transparent;
        border-radius: 5px;
        border: 1px solid $red;
        color: $red;

        &:hover {
            background-color: $red;
            color: $white; } }

    &[disabled] {
        background-color: $gray-tertiary;
        cursor: not-allowed;
        opacity: .5; }

    &_bounce {
        animation: bounce 1s infinite normal;
        background-color: $red;

        @keyframes bounce {
            0% {
                transform: translateY(0); }

            50% {
                transform: translateY(-3px); }

            100% {
                transform: translateY(0); } }

        &_chat {
            color: $white; } }

    &.back-white {
        background-color: $white;

        &:hover {
            background-color: $red; } } }

.btn-icon {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    position: relative;
    @include size(2.5rem);

    .stripe {
        background-color: $red;
        margin: auto;
        @include border-bottom-radius(1.5px);
        @include border-top-radius(1.5px);
        @include position(absolute, 0 0 0 0);
        @include size(1.25rem 3px); } }

.btn-back .stripe {
    transform-origin: left;
    @include size(.8125rem 3px);

    &:first-child {
        transform: rotate(-45deg) translateX(-1px); }

    &:last-child {
        transform: rotate(45deg) translateX(-1px); } }

.rotate-180 {
    transform: rotate(180deg); }

.btn-close .stripe {
    &:first-child {
        transform: rotate(-45deg); }

    &:last-child {
        transform: rotate(45deg); } }

.btn-menu .stripe {
    left: auto;

    &:first-child {
        transform: translateY(-230%);
        width: 1.125rem; }

    &:nth-child(2) {
        width: 1.5rem; }

    &:last-child {
        transform: translateY(230%);
        width: 0.875rem; } }

.btn-plus .stripe {
    &:first-child {
        transform: rotate(90deg); } }

.btn-dropdown .stripe {
    @include size(1em 2px);

    &:first-child {
        transform: rotate(90deg); } }

.main-nav {
    background-color: $gray-primary;
    bottom: 0;
    height: 3.75em;
    position: fixed;
    width: 100%;

    .container {
        display: flex; }

    .nav-button {
        color: $white;
        height: 3.75em;
        opacity: .5;
        text-align: center;
        text-decoration: none;
        text-transform: capitalize;
        width: 20%;

        @include grid-media($tablet) {
            align-items: center;
            display: flex;
            font-weight: 600;
            justify-content: center; }

        &_active, &.active {
            opacity: 1; }

        &:hover {
            opacity: 1;
            transition: opacity .4s ease-in-out; }

        p {
            font-size: 0.625em;
            position: relative;
            @include margin(0);

            &.badge:before {
                background-color: $white;
                border: 2px solid $red;
                box-sizing: border-box;
                content: ' ';
                position: absolute;
                right: 35%;
                top: -25px;
                @include border-bottom-radius(50%);
                @include border-top-radius(50%);
                @include size(6px); }

            @include grid-media($tablet) {
                font-size: 0.875em;

                &.badge:before {
                    left: -16px;
                    right: null;
                    top: 0; } } } }

    .nav-icon {
        fill: $white;
        margin-top: .625em;
        @include size(1.5em);

        @include grid-media($tablet) {
            margin: 0 auto; }

        @include grid-media($tablet) {
            margin: 0;
            padding-right: .625em; } }

    .main-container {
        align-items: center;
        background-color: $white;
        border-radius: 50%;
        border: 6px solid $green;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        transition: transform .3s ease-in-out;
        @include margin(-1.25em auto null auto);
        @include position(absolute, null 0 1rem 0);
        @include size(60px);

        @include grid-media($mobile) {
            border: 8px solid $green;
            @include size(76px); }

        &_red {
            border-color: $red; }

        &:hover {
            transform: scale(1.1); } }

    .service-icon {
        @include size(1.875em);

        &_green {
            fill: $green; }

        &_red {
            fill: $red; } }

    button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        outline: none;
        padding: none; } }
