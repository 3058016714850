.terms-and-conditions,
.about-us {
    .container {
        @include padding(null 1.875rem); }

    ol,
    ul {
        font-size: 1em;
        line-height: 1.5em;
        margin-bottom: 2em; }

    li {
        margin-bottom: 1rem;
        padding-left: .5rem; }
    a {
        color: $red;
        font-weight: 300; }

    h4 {
        @include margin(2em 0);
        text-transform: uppercase; } }

.message-page {
    .message-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center; }

    .icon {
        @include size(50px);

        @include grid-media($mobile) {
            @include size(80px); }

        &_red {
            fill: $red; }

        &_green {
            fill: $green; } }

    h4 {
        margin-bottom: 1rem;
        width: 100%; }

    p {
        font-size: .875rem;
        width: 100%; }

    .btn {
        margin-top: 1.5rem;
        max-width: 300px; } }

.empty-state {
    align-items: center;
    box-sizing: border-box;
    color: $gray-secondary;
    display: flex;
    flex-direction: column;
    text-align: center;
    @include padding(null 1.875rem);

    .icon-empty-state {
        fill: $gray-tertiary;
        opacity: .5;
        @include size(80px);

        @include grid-media($mobile) {
            @include size(150px); } } }
