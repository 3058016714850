.new-service-request {
    .new-service {
        align-items: center;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        margin-bottom: 1em;
        text-decoration: none;
        width: calc(50% - .5em);

        &:hover {
            box-shadow: 0 0 10px rgba(0,0,0,.1); }

        &:nth-child(odd) {
            margin-right: 1em; }

        @include grid-media($mobile) {
            flex-flow: row nowrap;
            height: 6.25em;
            justify-content: flex-start;
            margin: auto auto 1em;
            width: 23.125em;

            &:nth-child(odd) {
                margin-right: auto; } }

        .col-icon {
            border: 2px solid $red;
            margin: 0 0 1em;
            @include size(2.5em);
            @include border-bottom-radius(50%);
            @include border-top-radius(50%);

            @include grid-media($mobile) {
                margin: 0 1.875em 0 0; }

            .card-icon {
                @include margin(0); } } } }

.new-service-form {
    .container {
        margin-top: 6rem;

        @include grid-media($mobile) {
            margin-top: 9rem; }

        .container-white {
            margin: auto;
            max-width: 470px; }

        .card-text {
            color: $gray-primary;
            margin: 0;

            .title {
                margin: 0; }

            .link {
                font-size: .875em;
                @include margin(2rem 0); } } }

    .card-actions {
        align-items: center;
        display: flex;
        flex-direction: column;

        .btn {
            max-width: 300px;

            &:first-child {
                margin-bottom: 1rem; } }

        .warn-text {
            align-self: center;
            color: $gray-secondary;
            font-size: .8rem;
            margin: auto;
            padding-bottom: 2rem;
            max-width: 400px; } } }

.rate-service {
    .container {
        margin-top: 6rem;

        @include grid-media($mobile) {
            margin-top: 9rem; } }

    .container-white {
        margin: auto;
        max-width: 470px; }

    .card-title {
        text-align: center;
        width: 100%;

        p {
            @include margin(0); } }

    .card {
        color: $gray-primary; }

    .stars {
        text-align: center;
        @include padding(2rem 0); }

    .card-actions {
        margin-top: 2rem;

        .btn {
            @include margin(0 auto);
            max-width: 300px; } } }

.service-history {
    .rol {
        color: $red; }

    .margin-top {
        margin-top: .8rem; }

    .card-text {
        width: 100%;

        .service-status {
            &_canceled {
                margin-top: 0; }

            &_red {
                color: $red; }

            &_green {
                color: $green; }

            &_blue {
                color: $blue; } } }

    .card-actions {
        flex-flow: column nowrap;
        margin-top: 1.875rem;
        padding-top: 1.875rem;

        .btn {
            margin-bottom: .5rem;

            &:last-child {
                margin-bottom: 0; } } }

    .result-msg {
        border-bottom: 1px solid $gray-tertiary;
        font-size: .875rem;
        font-weight: 700;
        margin-top: 2rem;
        text-align: center;
        @include margin(0);
        @include padding(2rem 0); }

    .no-rate {
        font-size: .875rem;
        padding-top: 1rem; } }

.service-filter {
    background-color: $white;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    box-sizing: border-box;

    form {
        align-items: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column nowrap;

        @include grid-media($tablet) {
            flex-flow: row wrap;
            padding-top: 1.5rem; }

        @include grid-media($desktop) {
            flex-flow: row nowrap;
            justify-content: center;
            padding-top: 0; } }

    .filter-group {
        align-items: center;
        display: flex;
        font-size: .875rem;
        justify-content: flex-end;
        margin: .5rem 1rem 0;
        max-width: 15.625rem;
        width: 100%;

        @include grid-media($tablet) {
            margin: 0 1rem 0;
            width: calc(100%/3 - 2rem); }

        @include grid-media($desktop) {
            width: calc(100%/4 - 2rem); }

        input, select {
            background-color: $gray-quaternary;
            border: 1px solid $gray-tertiary;
            box-sizing: border-box;
            color: $gray-secondary;
            height: 1.875rem;
            min-width: 7.5rem;
            width: 60%;
            @include border-bottom-radius(5px);
            @include border-top-radius(5px);
            @include padding(0 1rem);
            @include margin(0 0 0 1rem);

            @include grid-media($tablet) {
                max-width: none;
                width: 100%; } } }

    .filter-label {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        font-size: .875rem;
        justify-content: space-between;

        .filter-icon {
            fill: $red;
            margin-right: .5rem;
            @include size(1.25rem); } }

    .filter-actions {
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        @include padding(1rem 0);

        .btn {
            font-size: .875rem;
            font-weight: 400;
            max-height: 1.875rem;
            min-width: 4.375rem;
            width: calc(50% - .5rem);
            @include padding(7px 10px);

            &:first-child {
                margin-right: 1rem; } } } }

.filter-error {
    color: $red;
    font-size: .75rem;
    padding-top: 1rem;
    text-align: center;
    @include margin(0); }

.service-map {
    .btn {
        outline: none; }

    p {
        color: $gray-primary;
        font-size: 1rem; }

    .top-control {
        background-color: $white;
        box-shadow: 0 0 10px rgba(0,0,0,.3);
        box-sizing: border-box;
        width: 100%;
        z-index: 1000010 !important;
        @include padding(1.25em);

        @include grid-media($mobile) {
            max-width: 400px;
            @include border-bottom-radius(5px);
            @include border-top-radius(5px);
            @include padding(1.875em); }

        .card-header-text {
            width: calc(100% - 60px);

            p {
                box-sizing: border-box;
                max-width: calc(100% - 32px);
                padding-right: 20px; }

            .card-icon {
                align-self: center; } } }

    .top-control {
        cursor: pointer;

        @include grid-media($mobile) {
 } }            //margin-top: 1.875rem

    input {
        background-color: $gray-quaternary;
        border: none;
        box-sizing: border-box;
        font-size: 1rem;
        @include border-bottom-radius(5px);
        @include border-top-radius(5px);
        @include margin(0 10px 0 0);
        @include padding(0 20px); }

    .bottom-control {
        width: 100%;
        z-index: 1000010 !important;

        @include grid-media($mobile) {
            max-width: 400px; }

        .card {
            background-color: $white;
            box-shadow: 0 0 10px rgba(0,0,0,.3);
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            margin-top: 0.5rem;
            width: 100%;
            @include padding(1.25rem);

            @include grid-media($mobile) {
                margin-bottom: 2.5rem;
                @include border-bottom-radius(5px);
                @include border-top-radius(5px);
                @include padding(1.875rem); } }

        .info {
            align-items: center;
            display: flex;
            flex-direction: row;
            margin-bottom: 1.5rem;
            position: relative;
            width: 100%;

            .info-detail {
                box-sizing: border-box;
                padding-right: 20px;
                width: calc(100% - 60px); }

            p {
                margin: 0;
                width: 100%; }

            .name {
                font-weight: 600; }

            .provider-type {
                color: $gray-secondary; }

            .rateit {
                margin-top: .5rem; }

            .btn-icon {
                position: absolute;
                right: -15px;
                top: -15px;

                .stripe {
                    @include size(1rem 2px); } } }

        .btn {
            font-weight: 400;
            width: 100%; } }

    .container-img {
        background-color: $gray-quaternary;
        background-size: cover;
        margin-right: 1.25rem;
        box-sizing: border-box;
        border: 2px solid $gray-quaternary;
        @include border-bottom-radius(50%);
        @include border-top-radius(50%);
        @include size(2.5rem); }

    .marker-control {
        left: 50% !important;
        margin-left: -20px;
        margin-top: -20px;

        .container-maker {
            background-repeat: no-repeat;
            @include size(2.5rem); } }

    .container-time {
        border-top: 1px solid $gray-quaternary;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 1rem;
        padding-top: 1rem;
        width: 100%;

        @include grid-media($mobile) {
            flex-wrap: nowrap; }

        &_schedule-picker {
            border-top: none;
            margin-top: 0;
            padding-top: 0; }

        .card {
            align-items: center;
            box-shadow: none;
            cursor: pointer;
            display: flex;
            margin-top: 1rem;
            @include padding(0);

            @include grid-media($mobile) {
                @include margin(0); }

            &:first-child {
                margin-top: 0; } }

        input {
            font-size: .875rem;
            width: 50%;
            @include margin(0);

            @include grid-media($mobile) {
                width: 100%;
                @include margin(10px 20px 0 0); } }

        p {
            font-size: .875rem;
            width: 50px;
            @include margin(0 40px 0 0);
            @include padding(.5rem 0); } }

    .switch-container {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;

        p {
            @include margin(.5rem null); }

        .switch {
            display: inline-block;
            position: relative;
            @include size(40px 24px);

            @include grid-media($mobile) {
                @include size(50px 30px); } }

        input {
            display: none; }

        .slider {
            background-color: $gray-tertiary;
            bottom: 0;
            cursor: pointer;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: color .4s ease-in-out;

            &:before {
                background-color: $white;
                box-shadow: 0 0 6px rgba(0, 0, 0, .3);
                content: "";
                left: -1px;
                position: absolute;
                top: -1px;
                transition: all .4s ease-in-out;
                @include size(26px);

                @include grid-media($mobile) {
                    @include size(32px); } } }

        .circle {
            @include border-top-radius(20px);
            @include border-bottom-radius(20px);

            &:before {
                @include border-top-radius(50%);
                @include border-bottom-radius(50%); } }

        input:checked + .slider:before {
            transform: translateX(20px); }

        input:checked + .slider {
            background-color: $red; } } }

.add-indications {
    .form-group {
        @include margin(0); }

    .btn {
        margin-bottom: 1rem; } }

.add-orders,
.add-formula {
    .form-container {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%; }

    .card {
        color: $gray-primary;

        .card-icon {
            &_close {
                margin-right: 0;
                margin-left: 1.25rem; } }

        .btn-icon {
            @include size(1.5rem); } }

    .item-group {
        font-weight: 600; }

    .form-group {
        margin-bottom: 1rem; }

    .link {
        text-decoration: underline;
        cursor: pointer; }

    .form-action {
        width: 50%; }

    .actions {
        text-align: center; }

    .btn {
        max-width: 300px; }

    select {
        @include margin(0); }

    input {
        border-width: 1px;
        box-sizing: border-box;
        margin-top: 0;
        padding-left: 1.25rem;
        @include border-bottom-radius(5px);
        @include border-top-radius(5px); }

    p {
        margin: 0; }

    textarea {
        height: 70px; }

    .stripe {
        @include size(1rem 2px); } }

.service-detail {
    text-align: center;

    .service-status {
        font-size: 1.5rem;
        font-weight: 400; }

    .animation-container {
        text-align: center;

        .service-animation {
            width: 250px;

            .path {
                stroke-dasharray: 150;
                stroke-dashoffset: 150;
                stroke-linecap: round;
                animation: dash 3s linear normal infinite;

                @keyframes dash {
                    0% {
                        stroke-dashoffset: 150; }

                    100% {
                        stroke-dashoffset: 0; } } } } }

    .service-info {
        font-size: .75rem;
        padding-top: 2rem;
        @include margin(0);

        &_legal {
            color: $red; } }

    .card-header-text {
        width: calc(100% - 44px); }

    .card {
        text-align: left; }

    .rateit {
        margin-top: .5rem; }

    .user__actions {
        .btn {
            margin-top: .5rem;

            &:first-child {
                margin-top: 0; } }

        &.inline {
            @include grid-media($mobile) {
                display: flex;

                .btn {
                    margin-top: 0;
                    width: calc(50% - .5rem);

                    &:first-child {
                        margin-right: 1rem; } } } } }

    .service-dashboard {
        .card {
            &:last-child {
                border-bottom: none;
                padding-bottom: 0; } } }

    .next-service {
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-top: 3rem;

        &_bounce {
            animation: bounce 1s infinite normal;
            box-shadow: 0 0 10px rgba(255,0,0,.3);

            @keyframes bounce {
                0% {
                    transform: translateY(0); }

                50% {
                    transform: translateY(-3px); }

                100% {
                    transform: translateY(0); } } }

        .service-preview {
            display: flex;
            flex-flow: row nowrap;
            font-size: .875rem;
            text-align: left;
            width: 100%;

            p {
                @include margin(0);
                @include padding(null 1rem); }

            .patient-image {
                background-color: $gray-quaternary;
                background-position: 50%;
                background-size: cover;
                border: 2px solid $gray-quaternary;
                box-sizing: border-box;
                @include border-bottom-radius(50%);
                @include border-top-radius(50%);
                @include size(2.5rem); }

            .label-service {
                color: $red; } } } }

.service-summary {
    .clasification {
        padding-bottom: 1.5rem;
        text-indent: 1.875rem; }

    .group-title {
        color: $gray-primary;
        font-size: .875rem;
        font-weight: 600;
        padding-bottom: .5rem;
        padding-top: .5rem;

        &:first-child {
            padding-top: 0; } }

    .card-text {
        .quantity {
            line-height: 2rem; }

        li {
            padding-bottom: .5rem; } }

    .rol {
        color: $red; }

    .info {
        color: $gray-primary; }

    .inner-header {
        padding: 0;

        .main-title {
            font-weight: 400; } }

    .description {
        font-size: .875rem; }

    .icon-empty-state {
        margin-top: 3rem;
        margin-top: 1rem;
        @include size(60px); } }

.schedule-next-service {
    .card-text {
        color: $gray-primary; }

    .card-actions {
        margin-top: 2.25rem; } }

.change-provider {
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: center;

    .card-icon {
        fill: $blue;
        @include size(50px); }

    h3 {
        border-bottom: 1px solid $gray-tertiary;
        @include padding(.5rem 0 3rem); }

    .actions {
        margin-top: 2rem;
        text-align: center;

        .btn {
            font-weight: 400;
            margin-top: .5rem;
            max-width: 300px;

            &:first-child {
                margin-top: 0; } } } }
