html {
    height: 100%;
    font-size: 100%; }

body {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1em;
    margin: 0;
    min-width: 20em;
    color: $gray-primary;
    background-color: $gray-quaternary;

    &.hide-header-left-btn {
        .action:first-child > * {
            display: none; } }

    &.big-header {
        header {
            height: 6.25em; }

        .section-margin-header {
            margin-top: 6.25em; } }

    &.background-black {
        background-color: $black; } }

p {
    line-height: 1.5em; }

.no-scroll {
    overflow: hidden;
    width: 100%;
    @include position(fixed, null null null 0); }

/* CONTAINER */
.container {
    margin: auto;
    max-width: $max-width;
    padding: 0 .75em;

    &_xs {
        max-width: 22em; }

    &_sm {
        max-width: 28.5em; }

    &_md {
        max-width: 41em; }

    &_xl {
        max-width: 60em; } }

.container-white {
    background-color: $white;
    @include padding(1.25em);
    @include border-bottom-radius(.3125em);
    @include border-top-radius(.3125em);

    @include grid-media($tablet) {
        @include padding(1.875em); } }

.unanswered-question {
    border: 1px solid $red; }

/* HELPERS */
.text-center {
    text-align: center; }

.margin-top {
    margin-top: 1rem; }

.line-bottom {
    border-bottom: 1px solid $gray-quaternary; }

.line-top {
    border-top: 1px solid $gray-quaternary; }

.no-visible {
    display: none; }

/* GENERAL */
.section-margin-header {
    margin-top: 4.375em; }

.section-margin-top {
    margin-top: 8em; }

.section-padding-bottom {
    padding-bottom: 8em; }

.steps-container {
    font-weight: 700;
    padding: 0 0 5em;
    text-align: center;

    .circle {
        border: 4px solid $gray-primary;
        box-sizing: border-box;
        opacity: .3;
        padding: 3px 0;
        position: relative;
        @include size(2em);
        @include border-top-radius(50%);
        @include border-bottom-radius(50%);

        @include grid-media($mobile) {
            padding: .375em 0;
            @include size(2.5em); }

        &.active {
            background-color: $green;
            border-color: $green;
            color: $white;
            opacity: 1; }

        .name-step {
            color: $gray-primary;
            font-size: 0.625em;
            transform: translateX(-50%);
            width: 5em;
            @include position(absolute, 2.5rem null null 50%);

            @include grid-media($mobile) {
                font-size: 0.75em;
                top: 2.8125rem;
                width: 6.25rem; } }

        .single-line {
            top: 2.8125rem;

            @include grid-media($tablet) {
                top: 3.1875rem; } } }

    .line {
        background-color: $gray-primary;
        opacity: .3;
        @include size(2.5rem 4px);

        @include grid-media($mobile) {
            width: 4rem; }

        @include grid-media($tablet) {
            width: 5rem; }

        &.active {
            opacity: 1;
            background-color: $green; } } }

.evaluation {
    &__title {
        color: $red;
        margin-bottom: 1em; } }

.messages {
    color: $white;
    font-size: 0.875em;
    font-weight: 700;
    padding: 1.5em 0;

    @include grid-media($tablet) {
        font-size: 1em; }

    &.success {
        background-color: $green;

        .icon-success {
            display: inline; } }

    &.error {
        background-color: $red;

        .icon-info {
            display: inline; } }

    .icon {
        display: none;
        fill: $white;
        margin-right: .5rem;
        @include size(1.5rem);
        vertical-align: middle; }

    &__text {
        width: calc(100% - 2rem);

        @include grid-media($tablet) {
            width: auto; } } }

/* TITLES */
.title {
    line-height: 1.5em;
    margin: 0;

    &_general {
        font-size: 1em;
        font-weight: 400; }

    &_xs {
        font-size: .75em;
        font-weight: 400; }

    &_sm {
        font-size: 1em;
        font-weight: 700;

        @include grid-media($tablet) {
            font-size: 1.25em; } }

    &_md {
        font-size: 1.25em;
        font-weight: 700;

        @include grid-media($tablet) {
            font-size: 1.5em; } }

    &_lg {
        font-size: 1.5em;
        font-weight: 700;

        @include grid-media($tablet) {
            font-size: 2em; } } }

/* GENERAL MESSAGES */
.general-messages {
    text-align: center;

    .btn {
        max-width: 18.75em; } }

.text {
    font-size: 1em;

    &_sm {
        font-weight: 400; }

    &_md {
        color: $black;
        font-weight: 600; }

    &_sm-gray {
        color: $gray-secondary; }

    &_gray-dark {
        color: $gray-primary; }

    &_head {
        font-size: .875em;
        padding: 2rem 0; }

    &_system {
        padding-top: .5rem; } }

.text-undecorated {
    text-decoration: none; }

.loaded-content {
    font-size: .75em;
    @include margin(2em 0); }

/* GENERAL */
.overlay-menu {
    transition: opacity .4s ease-in-out;
    opacity: 0;
    transform: translate(-100%, -100%);
    z-index: 30;
    @include position(fixed, 0 null null 0);
    @include size(100%);

    &.active {
        transform: translate(0); }

    &.show {
        opacity: 1;

        .menu-container {
            transform: translateX(0%); } }

    .overlay {
        background-color: $black;
        cursor: pointer;
        opacity: .6;
        @include size(100%); }

    .menu-container {
        background-color: $gray-quaternary;
        width: 100%;
        max-width: 23em;
        min-height: 100%;
        transition: transform .4s ease-in-out;
        transform: translateX(100%);
        @include position(absolute, 0 0 null null);

        header {
            position: static;
            height: 70px;

            .container {
                justify-content: flex-start; }

            h2 {
                text-align: center;
                width: calc(100% - 80px); } } }

    .scroll-panel {
        box-sizing: border-box;
        height: calc(100% - 70px);
        overflow: auto;
        padding-bottom: 5rem;
        position: absolute;
        width: 100%; }

    .btn-list {
        h4 {
            font-weight: 400;
            padding-left: 1.875rem; }

        a, button {
            align-items: center;
            background-color: $white;
            border-bottom: 1px solid $gray-quaternary;
            box-sizing: border-box;
            display: flex;
            height: 60px;
            text-decoration: none;
            width: 100%;
            @include padding(0 1.875rem);

            &:hover {
                color: $red; } }

        .menu-icon {
            fill: $red;
            margin-right: 1.25rem;
            vertical-align: center;
            @include size(1.5em); } }

    .actions-menu {
        text-align: center;

        .btn {
            max-width: 75%;
            @include margin(1.5rem auto 0); } }

    &_black {
        .menu-container {
            background-color: $gray-primary;
            color: $white;

            header {
                background-color: $black;
                border-color: $gray-primary; }

            .btn-list {
                a, button {
                    background-color: $black;
                    color: $white;
                    border-bottom: 1px solid $gray-primary;

                    &:hover {
                        color: $red; } } }

            .btn_red {
                &:hover {
                    background-color: $white;
                    color: $red; } } } } }

.pagination {
    color: $gray-secondary;
    height: 100px;
    width: 100%;

    ul {
        list-style-type: none;
        padding-left: 0; }

    li {
        display: inline-block;
        text-decoration: none;
        @include padding(0 10px); }

    a {
        text-decoration: none;

        &:hover {
            color: $red; } }

    .active {
        color: $gray-primary; }

    .disabled {
        .stripe {
            background-color: $gray-tertiary; } } }

#loading {
    position: fixed;
    background: rgba(50, 50, 50, 0.9);
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none; }

.slick-slider {
    .slick-arrow {
        transform: translateY(-50%);
        z-index: 2;
        @include position(absolute, 50% null null null); }

    .slick-slide {
        outline: none; }

    .slick-arrow-left {
        left: -1.5rem;

        @include grid-media($mobile) {
            left: 0; }

        @include grid-media($desktop) {
            left: 20%; } }

    .slick-arrow-right {
        right: -1.5rem;
        transform: rotate(180deg) translateY(50%);

        @include grid-media($mobile) {
            right: 0; }

        @include grid-media($desktop) {
            right: 20%; } }

    .slick-dots {
        display: flex;
        justify-content: center;
        list-style-type: none;
        @include padding(0);

        button {
            background-color: $gray-tertiary;
            border: none;
            cursor: pointer;
            font-size: 0;
            outline: none;
            @include border-bottom-radius(50%);
            @include border-top-radius(50%);
            @include margin(.5rem);
            @include size(1rem); } } }

.testimonials-provider {
    .btn-back {
        .stripe {
            background-color: $blue; } }

    .slick-active {
        button {
            background-color: $blue; } }

    .icon-quote {
        fill: $blue; } }

.testimonials-user {
    .btn-back {
        .stripe {
            background-color: $green; } }

    .slick-active {
        button {
            background-color: $green; } }

    .icon-quote {
        fill: $green; } }

.user-contact-form {
    .container {
        @include grid-media($tablet) {
            max-width: 570px; } }

    p {
         margin-bottom: 3rem;
         text-align: center; }

    .form-group {
        display: flex;
        flex-wrap: nowrap;

        &:last-child {
            margin-bottom: 0; } }

    .form-col {
        width: 100%; } }

.hide {
    display: none; }

/* RATE STARTS */
.rateit {
    width: 100%;

    div.rateit-range, div.rateit-hover, div.rateit-selected {
        background-image: url(../img/star.png); }

    div.rateit-range {
        background-position: 0 -2.5rem; }

    div.rateit-hover, div.rateit-selected {
        background-position: 0 0;
        cursor: pointer; } }

.medium-star {
    div.rateit-range, div.rateit-hover, div.rateit-selected {
        background-image: url(../img/medium.png); }

    div.rateit-range {
        background-position: 0 -1.5rem; } }

.big-star {
    div.rateit-range, div.rateit-hover, div.rateit-selected {
        background-image: url(../img/big.png); }

    div.rateit-range {
        background-position: 0 -34px; } }

.unread {
    box-shadow: 0 0 10px rgba(255,0,0,.3); }

.chat-wrapper {
    margin-bottom: 0;

    @include grid-media($mobile) {
        @include margin(0 auto 1rem); }

    .chat-history {
        box-sizing: border-box;
        height: calc(100vh - 8rem);
        overflow: auto;
        padding: 1.25rem;

        @include grid-media($mobile) {
            height: calc(100vh - 9.625rem); } }

    .card-icon {
        &.disabled {
            fill: $gray-tertiary; } }

    .chat-entry {
        background-color: $white;
        border-top: 1px solid $gray-quaternary;
        box-sizing: border-box;
        justify-content: space-between;
        margin: auto;
        max-width: 28.5rem;
        padding: .625rem 0 .9375rem;
        position: relative;
        width: 100%;

        @include grid-media($mobile) {
            @include border-bottom-radius(5px);
            @include border-top-radius(5px); }

        .input-messaje {
            border-color: rgba(170, 170, 170, .5);
            height: 1.875rem;
            margin-bottom: 0;
            margin-top: 0; }

        .send-button {
            background-color: transparent;
            border: none;
            box-sizing: border-box;
            margin: 0;
            width: 2.5rem;

            .card-icon {
                margin-right: 0;
                transform: rotate(-45deg); } }

        .counter {
            bottom: 4px;
            font-size: .5em;
            font-weight: 700;
            position: absolute;
            right: 2.5rem; }

        .img-container {
            margin: 0 1rem; } }

    .chat-message {
        align-items: flex-start;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        margin: auto;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
        max-width: 27.5625rem;
        width: 100%;

        p {
            margin: 0; }

        &.chat-right {
            align-items: flex-end; }

        .name-sender {
            color: $red; }

        .chat-rol {
            color: $gray-secondary;
            font-size: .75rem;
            margin-bottom: .25rem;

            &.operator {
                color: $white; } }

        .message-container {
            background-color: $white;
            border-bottom-left-radius: 1.5rem;
            box-shadow: 0 0 3px rgba(0,0,0,.1);
            max-width: 80%;
            padding: 1.25rem;
            @include border-right-radius(1.5rem);

            &.msg-right {
                border-bottom-right-radius: 0;
                border-top-left-radius: 1.5rem;
                border-top-right-radius: 1.5rem;
                @include border-left-radius(1.5rem); } } } }

.img-container {
    margin-right: 1rem;

    .user-img {
        background-color: $gray-quaternary;
        background-position: center;
        background-size: cover;
        border: 2px solid $gray-quaternary;
        box-sizing: border-box;
        @include border-bottom-radius(50%);
        @include border-top-radius(50%);
        @include size(30px);

        @include grid-media($mobile) {
            @include size(40px); } } }

.loading {
    align-items: center;
    background: rgba(50,50,50,0.9);
    display: flex;
    height: 100%;
    justify-content: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;

    &_hide {
        display: none; }

    .loader {
        animation: rotation 5000ms linear infinite;
        background-image: url(../svg/loader.svg);
        background-repeat: no-repeat;
        @include size(60px);

        @keyframes rotation {
          0% {
            transform: rotate(0deg); }

          100% {
            transform: rotate(360deg); } } } }

.section-margin-header {
    .picker__input {
        &[readonly] {
            background-color: transparent;
            cursor: default;
            opacity: 1; } } }

.picker {
    .documents-form-picker {
        background: transparent;
        height: 450px;
        top: 0; }

    .picker__holder {
        top: 4.3125rem;

        .picker__frame {
            max-width: 25rem; }

        .picker__box {
            font-family: 'Open Sans', sans-serif;
            font-weight: 400;
            font-size: 1rem;
            text-transform: capitalize; }

        .picker__list {
            padding: .5rem 0; }

        .picker__list-item {
            padding: 1rem; }

        .picker__button--today,
        .picker__button--clear,
        .picker__button--close {
            text-transform: capitalize;
            padding: 1rem;
            background-color: $gray-tertiary;
            border-radius: 5px;
            margin-bottom: 1rem;
            color: $white;
            font-size: 1rem;
            font-weight: 400;

            &:hover {
                background-color: $gray-primary; }

            &::before {
                display: none; } }

        .picker__year {
            font-family: 'Open Sans', sans-serif;
            font-size: 1rem;
            font-style: normal; }

        .picker__day--outfocus {
            color: $gray-tertiary; }

        .picker__day,
        .picker__day--outfocus,
        .picker__day--today, {
            &::before {
                border-top-color: $red; } }

        .picker__nav--next:before,
        .picker__nav--prev:before {
            &::before {
                border-right-color: $red; } }

        .picker__header {
            margin-top: 1rem;
            padding-bottom: 1rem; }

        .picker--focused,
        .picker__day--selected,
        .picker__day--selected,
        .picker__day--selected:hover {
            background-color: $red;
            border-radius: 5px; }

        .picker--focused,
        .picker__day--highlighted,
        .picker__day--highlighted:hover,
        .picker__day--infocus:hover,
        .picker__day--outfocus:hover {
            background-color: $gray-quaternary;
            border-radius: 5px; }

        .picker__day--highlighted:hover,
        .picker--focused .picker__day--highlighted {
            background-color: $gray-quaternary; }

        .picker--focused,
        .picker__day--highlighted,
        .picker__day--highlighted:hover,
        .picker__day--infocus:hover,
        .picker__day--outfocus:hover {
            background-color: $gray-secondary;
            color: $white; }

        .picker--focused,
        .picker__list-item--highlighted,
        .picker__list-item--highlighted:hover,
        .picker__list-item:hover {
            background-color: $gray-quaternary; }

        .picker__list-item--highlighted,
        .picker__list-item:hover {
            background-color: $gray-secondary;
            color: $white;
            border: none; } } }

.hide-disabled-values {
    .picker__list-item--disabled {
        display: none; } }
