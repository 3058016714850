$white:             #fff;
$black:             #000;

$red:               #ff3a32;

$gray-primary:      #333333;
$gray-secondary:    #777777;
$gray-tertiary:     #aaaaaa;
$gray-quaternary:   #f5f5f5;

$green:             #1dd465;

$blue:              #5bb2fd;

$orange:            #ff8f32;

$max-width:         1280px;
$max-width-xl:      1920px;

$mobile: ( media: 500px );
$tablet: ( media: 768px );
$desktop: ( media: 994px );
$desktop-xl: ( media: 1200px );
