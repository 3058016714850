.user {
    &__actions {
        padding: 2.5em 0 0;
        text-align: center;

        .btn {
            display: block;
            margin: auto;
            max-width: 18.75em;

            &.inline {
                align-items: center;
                display: flex;
                flex-flow: row nowrap;
                justify-content: center; } }

        .first-btn {
            margin-bottom: 2.5em; } }

    &_actions-grid-btn {
        &_landing {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between; }

        .btn {
            margin: initial;
            width: 45%; } }

    &__second-action {
        border-top: 1px solid $gray-tertiary;
        margin: 2.5em 0;
        padding-top: 2.5em;

        .btn {
            max-width: 100%; } }

    &__box {
        font-size: 1.5em;
        font-weight: 700;
        margin-top: 0.75em;
        text-align: center;

        &__score {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center; } }

    &__box-title {
        font-size: 0.875rem;
        font-weight: 400;
        margin-top: 0.5em; } }

.registration {
    margin-bottom: 2.5em;

    &__type {
        box-sizing: border-box;
        text-align: center;
        width: 100%;
        @include padding(2rem 2rem 4rem);

        @include grid-media($tablet) {
            padding: 3em;
            text-align: left;
            width: 45%; }

        &:first-child {
            border-bottom: 1px solid $gray-quaternary;

            @include grid-media($tablet) {
                margin-bottom: 0;
                border-bottom: 0; } }

        .btn {
            max-width: 17.5em;

            @include grid-media($tablet) {
                max-width: 12.5em; } } }

    &__icon {
        fill: $red;
        margin-bottom: 0.5em;
        @include size(3em); } }

.questionary-form {
    .container-white {
        margin-bottom: 1.5em; } }

.complementary-info-form {
    .user_actions-grid-btn {
        justify-content: flex-end; }

    .form-map {
        border: 1px solid $gray-tertiary;
        height: 200px;
        width: 100%;
        @include border-bottom-radius(5px);
        @include border-top-radius(5px);

        @include grid-media($mobile) {
            height: 250px; } } }

.creditcard-list {
    .title {
        text-indent: 1.875rem;
        @include padding(2em 0); }

    a {
        cursor: pointer; }

    p {
        color: $gray-primary;
        @include margin(0); }

    .card-header-text.credit-card {
        width: calc(100% - 44px);

        .card-icon {
            fill: $gray-tertiary;
            padding-right: 0;
            transition: fill 0.4s ease-in-out;

            &:hover {
                fill: $red; } } } }

.change-password-form {
    .form-group {
        &:last-child {
            margin-bottom: 0; } } }

.login {
    text-align: center;

    &__icon {
        padding: 4em 0;
        @include size(9.375em 6.25em); }

    .form-group label {
        text-align: left; }

    .btn {
        margin-bottom: 2.5em; } }

.account-form {
    input, textarea {
        background-color: $gray-quaternary;
        border: none;
        box-sizing: border-box;
        margin-top: 1rem;
        @include border-bottom-radius(5px);
        @include border-top-radius(5px);
        @include padding(0 1rem); }

    textarea {
        @include padding(1rem); }

    label {
        color: $gray-primary;
        line-height: 1.3em; }

    .info {
        width: 100%;

        @include grid-media($tablet) {
            margin-left: 2em;
            width: 50%; } }

    .form-container {
        display: flex;
        flex-wrap: wrap;
        @include margin(0 auto);

        .card {
            margin-top: 1.875rem;
            padding-top: 1.875rem;
            width: 100%;

            &:first-child {
                margin-top: 0;
                padding-top: 0; } }

        .form-group {
            margin: 0;

            li {
                &:before {
                    display: none; } } }

        .actual-info {
            margin-top: 1rem;
            @include margin(1rem 0 0); }

        .form-map {
            border: 1px solid $gray-tertiary;
            height: 200px;
            margin-top: 1rem;
            width: 100%;
            @include border-top-radius(5px);
            @include border-bottom-radius(5px); } }

    .container-header {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin: auto;
        max-width: 30em;
        padding: 2em 1em 1em;
        text-align: center;

        @include grid-media($tablet) {
            flex-wrap: nowrap;
            justify-content: space-between;
            padding: 3.4375em 0 2.5em 5em;
            text-align: left; }

        .title {
            font-size: 1.5em;
            font-weight: 600;
            margin-bottom: .5rem; }

        p {
            margin: 0; } }

    .container-img {
        background-position: center;
        background-size: cover;
        border: .625em solid $white;
        box-shadow: 0 0 10px rgba(0, 0, 0, .1), inset 0 0 10px rgba(0, 0, 0, .1);
        box-sizing: border-box;
        height: 11.25em;
        min-width: 11.25em;
        padding: 3px 0;
        position: relative;
        text-align: center;
        @include border-top-radius(50%);
        @include border-bottom-radius(50%);

        input {
            cursor: pointer;
            font-size: 0;
            margin-top: 0;
            opacity: 0;
            padding: 0;
            position: absolute;
            right: -7px;
            top: -5px;
            @include size(50px); } }

    .image-change {
        background-color: $white;
        display: inline-block;
        position: absolute;
        right: 0;
        @include border-bottom-radius(50%);
        @include border-top-radius(50%);
        @include size(36px);

        .icon {
            fill: $red;
            padding-top: 5px;
            @include size(26px); } } }

.patient-resume, .notification-list {
    line-height: 1.5em;

    .slick-slide {
        background-size: cover;
        display: flex;
        padding: 0 2rem;
        position: relative;
        @include border-bottom-radius(5px);
        @include border-top-radius(5px);
        @include size(auto 200px);

        @include grid-media($tablet) {
            margin: 0 .9em; } }

    .news-container {
        display: flex;
        flex-wrap: wrap;
        padding-top: 1.5rem;

        .news-box {
            align-items: center;
            background-size: cover;
            box-sizing: border-box;
            display: flex;
            height: 250px;
            justify-content: flex-end;
            margin-bottom: 1.875rem;
            position: relative;
            width: 100%;
            @include padding(1.875rem);
            @include border-top-radius(5px);
            @include border-bottom-radius(5px);

            @include grid-media($tablet) {
                margin-right: 30px;
                width: calc(50% - 15px);

                &:nth-child(2n) {
                    margin-right: 0; } }

            @include grid-media($desktop) {
                margin-right: 30px;
                width: calc(99%/3 - 20px);

                &:nth-child(2n) {
                    margin-right: 30px; }

                &:nth-child(3n) {
                    margin-right: 0; } } } }

    .dots-slider {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        list-style: none;
        text-align: center;
        @include padding(0);

        @include grid-media($tablet) {
            position: relative; }

        li {
            background-color: transparent;
            border: 1px solid $gray-tertiary;
            cursor: pointer;
            margin: 0 .625em;
            @include size(20px);
            @include border-top-radius(50%);
            @include border-bottom-radius(50%);

            &.slick-active {
                background-color: $white;
                border: 1px solid $red; }

            button {
                font-size: 0;
                opacity: 0; } } }

    .container-text {
        color: $white;
        font-size: .875em;
        font-weight: 700;
        max-width: 200px;
        text-align: right;
        z-index: 0; }

    .container {
        margin-top: 6em; }

    .container-white {
        margin-bottom: 1em;

        @include grid-media($mobile) {
            margin-bottom: 2em; } }

    .alert {
        color: $red; } }

.patient-resume, .service-history {
    .container-service {
        display: flex;
        flex-flow: row wrap;
        width: 100%; }

    .container-clasification {
        @include grid-media($mobile) {
            max-width: 28.5em;
            @include margin(0 auto); }

        @include grid-media($tablet) {
            max-width: 100%; } }

    .clasification {
        padding-bottom: 1.5rem;
        text-indent: 1.875rem;
        @include margin(0);

        &_first {
            padding-top: 1.5rem; } }

    .slider-container {
        padding-top: 1.5rem; }

    .container-white {
        box-sizing: border-box;
        @include size(100%);

        @include grid-media($tablet) {
            margin-right: 2rem;
            width: calc((100% / 2) - 1rem);

            &:nth-child(2n) {
                margin-right: 0rem; } }

        @include grid-media($desktop) {
            width: calc((100% / 3) - (4rem / 3));

            &:nth-child(2n) {
                margin-right: 2rem; }

            &:nth-child(3n) {
                margin-right: 0; } } }

    .expand-alert {
        border-top: 1px solid $gray-quaternary;
        padding: 1.5em 0 0;
        text-align: center; }

    .warn-text {
        color: $gray-secondary;
        font-size: .8em; } }

.orders-section {
    .empty-state {
        width: 100%; }

    .order-container {
        display: flex;
        flex-wrap: wrap;
        max-width: 28.5em;
        padding-top: 3rem;
        @include margin(0 auto);

        @include grid-media($tablet) {
            max-width: 100%; } }

    .container-white {
        box-sizing: border-box;
        @include size(100%);

        @include grid-media($tablet) {
            margin-right: 2rem;
            width: calc((100% / 2) - 1rem);

            &:nth-child(2n) {
                margin-right: 0rem; } }

        @include grid-media($desktop) {
            width: calc((100% / 3) - (4rem / 3));

            &:nth-child(2n) {
                margin-right: 2rem; }

            &:nth-child(3n) {
                margin-right: 0; } } }

    .group-title {
        color: $gray-primary;
        font-weight: 600;
        margin-top: .5rem;

        &:before {
            display: none; }

        &:first-child {
            margin-top: 0; } }

    .quantity {
        line-height: 2rem; }

    .section-title {
        color: $gray-primary;
        font-weight: 600;
        padding-bottom: 1rem; } }

.tabs-nav {
    background-color: $white;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
    box-sizing: border-box;
    @include size(100% 52px);

    .tabs-container {
        display: flex;
        height: 100%;
        max-width: 770px;
        width: 100%;
        @include margin(0 auto); }

    .tab {
        width: calc(100% / 3); }

    a {
        align-items: center;
        border-left: 1px solid $gray-quaternary;
        display: flex;
        font-size: .875rem;
        font-weight: 600;
        height: 50px;
        justify-content: center;
        text-decoration: none;

        &:last-child {
            border-right: 1px solid $gray-quaternary; }

        &:hover {
            border-bottom: 2px solid $red; } }

    .active {
        border-bottom: 2px solid $red; } }

.quotation-request {
    .container {
        max-width: 470px; }

    .card-actions {
        margin-top: 3rem; }

    input {
        border 1px solid $gray-secondary {}
        border-width: 1px;
        box-sizing: border-box;
        max-width: 100px;
        text-align: center;
        @include border-bottom-radius(5px);
        @include border-top-radius(5px);
        @include margin(0);
        @include padding(0 15px); }

    table {
        border-collapse: collapse;
        color: $gray-secondary;
        font-size: .875rem;
        font-weight: 400;
        width: 100%;

        .indicators {
            display: none;

            @include grid-media($mobile) {
                display: table-row; } }

        th {
            color: $red;
            font-weight: inherit;
            border-bottom: 1px solid $red;
            padding-bottom: 1rem;

            &:first-child {
                text-align: left; }

            &:nth-child(2) {
                text-align: center; }

            &:last-child {
                text-align: right; } }

        tr {
            border: 1px solid $gray-quaternary;
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 2rem;
            @include border-bottom-radius(5px);
            @include border-top-radius(5px);
            @include padding(1.5rem);

            @include grid-media($mobile) {
                border: none;
                display: table-row; } }

        tr.item-group {
            background-color: $gray-quaternary;
            color: $gray-primary;
            font-weight: 600;

            @include grid-media($mobile) {
                background-color: transparent;
                font-weight: 400; }

            td {
                text-align: center;

                @include grid-media($mobile) {
                    border-bottom: 1px solid $gray-tertiary;
                    text-align: left;
                    @include padding(2rem null null); } } }

        td {
            border-bottom: 1px solid $gray-quaternary;
            line-height: 1.4rem;
            text-align: left;
            width: 100%;
            @include padding(1rem 0);

            @include grid-media($mobile) {
                width: initial; }

            &:first-child {
                padding-top: 0;

                @include grid-media($mobile) {
                    padding-right: 1rem;
                    padding-top: 1rem; } }

            &:nth-child(2) {
                @include grid-media($mobile) {
                    text-align: center;
                    @include padding(0 1rem); } }

            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
                position: relative;

                @include grid-media($mobile) {
                    border-bottom: 1px solid $gray-quaternary;
                    padding-bottom: 1rem;
                    text-align: right;
                    width: 120px; } }

            .error {
                bottom: -10px;
                color: $red;
                font-size: .75em;
                left: 0;
                line-height: 1rem;
                position: absolute;

                @include grid-media($mobile) {
                    bottom: 0;
                    right: 0; } }

            .mobile-description {
                color: $red;
                display: inline-block;
                @include padding(null 1rem .5rem null);

                @include grid-media($mobile) {
                    display: none; } } }

        .total-cell {
            color: $red;
            text-align: center;

            &:first-child {
                width: 100%;

                @include grid-media($mobile) {
                    text-align: left;
                    width: inherit; } }

            &:nth-child(2) {
                padding: 0;
                visibility: hidden;

                @include grid-media($mobile) {
                    visibility: visible; } }

            &:last-child {
                @include grid-media($mobile) {
                    text-align: right; } } } } }

.quotation-list {
    @include grid-media($mobile) {
        max-width: 28.5em; }

    @include grid-media($tablet) {
        max-width: $max-width; }

    .empty-state {
        padding-top: 5rem;
        width: 100%; }

    .quotation-general-info {
        background-color: $white;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        @include padding(1.5rem);
        @include border-bottom-radius(.3125em);
        @include border-top-radius(.3125em);

        @include grid-media($mobile) {
            @include padding(2.5rem); }

        @include grid-media($desktop) {
            flex-flow: row nowrap;
            justify-content: space-between;
            max-width: 100%; }

        .card {
            color: $gray-primary;
            justify-content: flex-start;
            margin-bottom: 1rem;
            width: 100%;

            &:last-child {
                margin-bottom: 0; }

            @include grid-media($desktop) {
                justify-content: center;
                margin-bottom: 0;
                width: calc((100% / 3 - 1.875rem); } } }

    .quotation-container {
        display: flex;
        flex-flow: row wrap;

        .container-white {
            box-sizing: border-box;
            @include size(100%);

            @include grid-media($tablet) {
                margin-right: 2rem;
                width: calc((100% / 2) - 1rem);

                &:nth-child(2n) {
                    margin-right: 0rem; } }

            @include grid-media($desktop) {
                width: calc((100% / 3) - (4rem / 3));

                &:nth-child(2n) {
                    margin-right: 2rem; }

                &:nth-child(3n) {
                    margin-right: 0; } } } }

    p {
        margin: 0; }

    .checkbox__block {
        border-width: 1px; }

    .warn-text {
        font-size: .875rem;
        text-align: center;
        @include padding(2rem 1rem); }

    table {
        border-collapse: collapse;
        font-size: .875rem;
        margin-top: .5rem;
        width: 100%;

        th {
            border-bottom: 1px solid $red;
            color: $red;
            font-weight: inherit;
            @include padding(1rem null .5rem);

            &:first-child {
                border-bottom: 0; }

            &:nth-child(2) {
                text-align: left; }

            &:last-child {
                text-align: right; } }

        td {
            border-bottom: 1px solid $gray-quaternary;
            line-height: 1.1rem;
            vertical-align: top;
            @include padding(1rem 10px);

            &:first-child {
                padding-left: 0;
                border-bottom: 0; }

            &:nth-child(2) {
                padding-left: 0; }

            &:nth-child(3) {
                text-align: center; }

            &:last-child {
                padding-right: 0;
                text-align: right; } } }

    .btn {
        font-weight: 400; } }

.bank-information {
    .card {
        color: $gray-primary;
        line-height: 1.5rem;

        a {
            word-break: break-all; } }

    .description-text {
        font-size: .75rem;
        padding-bottom: 1rem; }

    .info {
        font-size: .75rem;
        text-align: center;
        @include margin(0 1.5rem); } }

.chat-section {
    ::-webkit-scrollbar {
        width: 15px; }

    ::-webkit-scrollbar-track {
        background: $gray-tertiary;
        border: 7px solid transparent;
        background-clip: content-box; }

    ::-webkit-scrollbar-thumb {
        background: $red;
        border: 6px solid transparent;
        border-radius: 10px;
        background-clip: content-box; } }
