.service-control-center {
    .map {
        box-sizing: border-box;
        height: calc(100vh - 8.25rem);
        top: 3.875rem; }

    .controls-container {
        align-items: center;
        background-color: $black;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        margin: auto;
        max-width: $max-width;
        z-index: 1;
        font-size: .875rem;
        @include padding(.9375rem .75rem .9375rem .9375rem);
        @include position(fixed, 4.375rem 0 null 0);

        select, input {
            background-color: $white;
            border: none;
            color: $gray-secondary;
            height: 1.875rem;
            margin-right: 1.875rem;
            margin-top: 0;
            max-width: 10.25rem;
            padding: 0 2.5em 0 1.25em;
            @include border-bottom-radius(5px);
            @include border-top-radius(5px);

            &::placeholder {
                color: $gray-secondary; } }

        .control {
            background-color: $gray-secondary;
            color: $white;
            height: 1.875rem;
            width: 100px;
            font-size: .875rem;
            @include padding(7px 10px);

            &.btn-date {
                align-self: flex-end;

                @include grid-media($mobile) {
                    align-self: center; } } } }

    .flag {
        background-color: $red;
        transition: transform .4s ease-in-out;
        z-index: 2;
        @include size(2.5rem);
        @include position(fixed, 80px null null 0);

        &.active {
            transform: translateX(20rem);

            .close {
                display: inline-block; }

            .filter {
                display: none; } }

        .close {
            display: none;

            .stripe {
                background-color: $white; } }

        .filter {
            display: inline-block; }

        .icon-btn {
            fill: $white; } } }

.control-center-menu {
    background-color: $gray-primary;
    box-sizing: border-box;
    height: calc(100vh - 8.25rem);
    transform: translateX(-20rem);
    transition: transform .4s ease-in-out;
    z-index: 1;
    @include position(fixed, 8.25rem null null 0);

    &.active {
        overflow: auto;
        transform: translateX(0);
        width: 20rem; }

    &.heatmap {
        height: calc(100vh - 4.375rem);
        top: 4.375rem;

        .menu-container {
            border-top: 1px solid rgba(255,255,255,0.1);

            .menu-body {
                .input-disabled {
                    opacity: .4; }

                .line-bottom {
                    border-bottom: 1px solid rgba(255,255,255,0.1); }

                .input {
                    color: $gray-tertiary;

                    &_check {
                        margin-right: .625rem; } } } }
        .errorlist {
            color: $red;
            font-size: .75rem;
            list-style-type: none;
            margin-top: .5rem; } }

    .menu-container {
        font-size: .875rem;
        max-width: 20rem;
        width: 100%;

        .btn {
            align-items: center;
            display: flex;
            height: 3rem;
            justify-content: center;
            padding: 0; }

        .menu-header {
            .btn-container {
                align-items: center;
                border-bottom: 1px solid rgba(255,255,255,0.1);
                display: flex;
                height: 2.75rem;
                justify-content: flex-end;
                padding: 0 1.25rem;

                &_back-btn {
                    justify-content: space-between; }

                .stripe {
                    background-color: $white;
                    height: 2px; }

                .btn-icon {
                    @include size(1.25rem); } }

            .alert {
                height: 4.625;
                padding: 1.25rem 0 1.25rem 1.25rem;

                &_confirm {
                    background-color: $green; }

                &_warning {
                    background-color: $orange; }

                &_critical {
                    background-color: $red; }

                .card-icon {
                    fill: $white; } } }

        .menu-body-status {
            padding-left: 1.25rem;
            color: $white; }

        .menu-body {
            padding: 1.25rem 0 0 1.25rem;
            color: $white;

            label {
                &.required {
                    font-size: .75rem;

                    &::before {
                        color: $white; } } }

            &_notification {
                padding: 1.25rem;

                .input {
                    color: $gray-tertiary;

                    &:focus {
                        border-color: $gray-secondary; } }

                form {
                    padding-top: 1.25rem; } }

            &_chat {
                padding: 0;
                .chat-wrapper {
                    .chat-history {
                        height: 26.6875rem;

                        .message-container {
                            color: $black; } }

                    .chat-entry {
                        align-items: center;
                        background-color: $black;
                        border-top: none;
                        display: flex;
                        height: 3.5rem;
                        padding: 1.156rem 1.25rem;
                        position: initial;
                        @include border-bottom-radius(0);
                        @include border-top-radius(0);

                        .input-message-black {
                            border: none;
                            color: $gray-secondary;
                            margin: 0; } }

                    .actions {
                        padding: 1.5rem 1.25rem; } } } }

        //*SCROLL*//
        ::-webkit-scrollbar {
            width: 15px; }

        ::-webkit-scrollbar-track {
            background: rgba(252,252,252,0.6);
            border: 7px solid transparent;
            background-clip: content-box; }

        ::-webkit-scrollbar-thumb {
            background: $white;
            border: 6px solid transparent;
            border-radius: 10px;
            background-clip: content-box; }


        .title {
            font-weight: 600;

            &_md {
                font-size: 1rem; } }

        .card {
            color: $white;

            .card-text {
                padding-right: 1.25rem;

                &.line-bottom {
                    border-bottom: 1px solid rgba(255,255,255,0.1); }

                &.line-top {
                    border-top: 1px solid rgba(255,255,255,0.1); } } }

        .actions {
            padding: 0 1.25rem;

            .btn_light-gray {
                font-weight: 400;
                margin-bottom: 1rem;

                &:hover {
                    background-color: $red; } } }

        .card-text {
            word-break: break-all; } } }

.control-center-service-list {
    .container {
        max-width: 450px;
        padding-top: 220px;

        @include grid-media($desktop) {
            max-width: $max-width;
            padding-top: 5rem; } }

    h4 {
        text-align: center; }

    .picker__input {
        &[readonly] {
            background-color: $white; } }

    .services-list {
        margin-top: 3rem;

        table {
            border-collapse: collapse;
            font-size: .75rem;
            table-layout: fixed;
            text-align: left;
            width: 100%;

            .small-col {
                width: 80px; }

            .info-indicator {
                display: none;

                @include grid-media($desktop) {
                    display: table-row; } }
            tr {
                border: 1px solid $gray-tertiary;
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 2rem;

                @include grid-media($desktop) {
                    display: table-row;
                    border: none;

                    &:hover {
                        background-color: $white; } }

                th {
                    background-color: $gray-secondary;
                    color: $white;
                    font-weight: 600;
                    @include padding(1rem 10px); }

                td {
                    line-height: 1.1rem;
                    width: 100%;
                    @include padding(0 0 1rem 1rem);

                    &:first-child {
                        padding-top: 1rem; }

                    @include grid-media($desktop) {
                        border-bottom: 1px solid $gray-tertiary;
                        width: initial;
                        @include padding(1rem 10px); }

                    span {
                        display: inline;
                        font-weight: 600;

                        @include grid-media($desktop) {
                            display: none; } } } } } }

    .control-center-filters {
        background-color: $black;
        position: fixed;
        width: 100%;

        .controls-container {
            margin: auto;
            max-width: $max-width;
            padding: 0 .75em; }

        form {
            display: flex;
            flex-flow: column wrap;
            font-size: .875rem;
            justify-content: center;
            margin: 0 auto;
            max-width: 450px;
            z-index: 1;
            @include padding(1rem 0);

            @include grid-media($desktop) {
                flex-flow: row nowrap;
                justify-content: flex-end;
                max-width: $max-width; } }

        .filter-container {
            align-items: flex-start;
            display: flex;
            justify-content: center;
            margin-right: 1.875rem;
            padding-bottom: 1rem;

            &:last-child {
                padding-bottom: 0; }

            @include grid-media($desktop) {
                flex-flow: row nowrap;
                justify-content: initial;
                padding-bottom: 0; } }

        .filter-icon {
            fill: $white;
            margin-right: 1.875rem;
            @include size(1.5rem); }

        select, input {
            background-color: $white;
            border: none;
            box-sizing: border-box;
            color: $gray-secondary;
            height: 1.875rem;
            margin-top: 0;
            width: 150px;
            @include border-bottom-radius(5px);
            @include border-top-radius(5px);
            @include padding(0 2rem 0 1.25rem);

            &::placeholder {
                color: $gray-secondary; } }

        .btn {
            background-color: $gray-secondary;
            color: $white;
            font-size: .875rem;
            margin: 0 auto;
            max-height: 30px;
            width: 100px;
            @include padding(7px 10px);

            @include grid-media($desktop) {
                margin: initial; } }

        .errorlist {
            color: $red;
            font-size: .75rem;
            list-style-type: none;
            margin-bottom: 0;
            padding-left: 0; } } }

.heatmap-section {
    margin-top: 0; }
