.profile-review {
    .selector {
        background-color: $white;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
        box-sizing: border-box;
        @include padding(1.5em); }

    select {
        &.border-select {
            width: 10em;

            @include grid-media($tablet) {
                margin-top: 0;
                width: 12.5em; } } }

    .applicant-box {
        border: 1px solid $white;
        display: block;
        margin-bottom: 1em;
        text-decoration: none;

        &:hover {
            box-shadow: 0 0 10px rgba(0,0,0,.1); } }

    .stars {
        margin-left: 1rem; } }

.personal-documentation {
    a {
        .card-icon {
            fill: $gray-tertiary;
            transform: rotate(180deg);

            &:hover {
                fill: $red;
                transition: fill .4s ease-in-out; } } } }

.applicant-review, .professional-rating {
    .title_sm {
        font-size: 1em;
        font-weight: 400;
        text-indent: 1.875rem;
        @include padding(1.875em null); }

    .applicant-list {
        font-size: 1em;
        text-align: left;
        width: auto;
        @include margin(0);

        &:before {
            @include margin(0 1.25rem 0 0); } }

    p {
        @include margin(0); }

    .card {
        .card-text {
            width: calc(100% - 44px); } }

    .text-title {
        color: $gray-primary; }

    .personal-info {
        p {
            padding-bottom: .5rem; } }

    .container {
        display: flex;
        flex-wrap: wrap; }

    .content-box {
        max-width: 28.5em;
        width: 100%;
        @include margin(1.875rem auto null);

        @include grid-media($tablet) {
            max-width: 100%;
            padding-right: 1.875em;
            width: calc(50% - .9375rem);

            &:nth-child(2) {
                padding-right: 0; }

            &:nth-child(3) {
                width: 100%;
                padding-right: 0; }

            &:nth-child(4) {
                padding-right: 1.875em; }

            &:nth-child(5) {
                padding-right: 0; } }

        &:nth-child(1) {
            margin-top: 0; }

        &:nth-child(2) {
            margin-top: 0; } } }

.professional-rating {
    .container-white {
        width: 100%;

        &:last-child {
            margin-top: 1rem; } }

    .personal-info {
        .card {
            margin: 0;
            width: 100%;

            @include grid-media($tablet) {
                border: none;
                padding-bottom: 0;
                width: calc(50% - 1rem);

                &:nth-child(2) {
                    margin: 0 0 0 2rem; } } } }

    .card {
        align-items: flex-start; }

    .states-box {
        display: flex;
        flex-flow: row wrap;

        .pagination {
            height: auto;
            margin: 0; }

        .card {
            width: 100%;

            @include grid-media($mobile) {
                width: calc(50% - 1rem);

                &:nth-child(odd) {
                    margin-right: 2rem; } } } } }

.questionary-box.card {
    flex-wrap: wrap;
    width: auto;

    .card-icon {
        align-self: flex-start; }

    .answers-box {
        align-self: flex-start;
        width: 100%;

        @include grid-media($tablet) {
            margin-right: 3.75rem;
            width: calc(50% - 1.875rem);

            &:nth-child(odd) {
                margin-right: 0; } }

        &:last-child {
            margin-bottom: 0; } }

    .question-item {
        &:last-child {
            align-items: center;
            margin-top: 1rem; }

        .text-title {
            font-size: .75rem;
            padding-bottom: 1rem; }

        .question-text {
            font-size: .875rem; }

        .answer-text {
            color: $gray-primary;
            font-size: .75rem; }

        .good-answer {
            fill: $green; } } }

.applicant-list {
    font-size: 0.75em;
    line-height: 1.25em;
    text-align: center;
    text-transform: capitalize;
    width: 6.25em;

    .grid-flex-row-top {
        align-items: center;
        justify-content: flex-start; }

    &:before {
        background-color: transparent;
        border: 2px solid $white;
        box-sizing: border-box;
        content: " ";
        display: block;
        margin: 0px auto 6px auto;
        @include border-bottom-radius(50%);
        @include border-top-radius(50%);
        @include size(1.5em); }

    &_400:before {
        border-color: $red; }

    &_410:before {
        border-color: $blue;
        opacity: .5; }

    &_420:before {
        border-color: $red;
        opacity: .5; }

    &_430:before {
        border-color: $green;
        opacity: .5; }

    &_440:before {
        border-color: $blue; }

    &_450:before {
        border-color: $orange; }

    &_460:before {
        background-color: $blue;
        border-color: $blue; }

    &_470:before {
        background-color: $orange;
        border-color: $orange; }

    &_480:before {
        background-color: $green;
        border-color: $green; }

    &_490:before {
        background-color: $red;
        border-color: $red; } }

.states-box {
    width: 100%;

    .card-container {
        flex-wrap: wrap;

        &:last-child {
            padding-bottom: 0; } }

    .state-change {
        align-items: center;
        color: $gray-primary;
        display: flex;
        font-size: .75rem; }

    .card-text {
        width: 100%; }

    .text-title {
        font-size: .75rem;
        padding-bottom: 1rem; }

    .description-text {
        font-size: .875rem;
        padding-bottom: 1rem; } }

.state-changue.card {
    flex-wrap: wrap;

    form {
        width: 100%; } }

.sales-report {
    .card {
        color: $gray-primary; }

    .form-group {
        &:last-child {
            margin-bottom: 0; } }

    .user__actions {
        button {
            margin-top: .5rem;

            &:first-child {
                margin-top: 0; } } } }

.registration-form {
    .checkbox {
        max-width: 20px;
        margin-right: 1rem; }

    .checkbox__container {
        align-self: flex-start; }

    label {
        &.error {
            color: $red;
            font-size: .75em;
            line-height: 1.75em;
            margin: 1em 0 0;
            display: block; } } }

