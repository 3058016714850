.card-margin-top {
    margin-top: 1.5rem; }

.card-margin-bottom {
    margin-bottom: 1.5rem; }

.card-padding-top {
    padding-top: 1.25rem; }

.card-padding-bottom {
    padding-bottom: 1.25rem; }

.card {
    align-items: center;
    color: $gray-secondary;
    display: flex;
    flex-flow: row nowrap;

    .card-text-icon {
        width: calc(100% - 44px); }

    .card-text {
        p {
            @include margin(0); } }

    ul {
        font-size: .875rem;
        list-style-type: none;
        padding-left: 0;
        @include margin(0); }

    li {
        line-height: 1.3rem;
        padding-bottom: .5rem;

        &:before {
            color: $gray-secondary;
            content: "-  ";
            margin-left: -8px; }

        &:last-child {
            padding-bottom: 0; } }

    .card-icon {
        fill: $red;
        margin-right: 1.25rem;
        @include size(24px);

        &_text {
            align-self: flex-start; } }

    .card-header-text {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;

        p {
            @include margin(0); }

        .card-icon {
            align-self: flex-start;
            margin-left: .5rem;
            margin-right: 0; }

        .title {
            color: $gray-primary; } } }

.card-actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    .btn {
        font-weight: 300;
        max-width: 300px;
        margin: 0 auto; }

    .btn-double {
        width: calc(50% - .5rem);

        &:first-child {
            margin-right: 1rem; } } }

.card-actions-column {
    display: flex;
    flex-flow: column wrap;

    .btn {
        font-weight: 300;
        margin-top: .5rem;

        &:first-child {
            margin-top: 0; } } }

.dynamic-button {
    .stripe {
        &:first-child {
            display: none; } } }

.card-container {
    display: flex;
    flex-wrap: nowrap;
    width: 100%; }

.form-errors {
    color: $red;
    font-size: 0.75em;
    line-height: 1.75em;
    @include margin(0);
    @include padding(null null null 1.25rem);

    @include grid-media($tablet) {
        @include padding(null null 1em 1.875rem); } }

.schedule {
    .container {
        text-align: center;
        max-width: 450px;

        @include grid-media($tablet) {
            max-width: 770px; } }

    .card {
        color: $gray-primary; }

    .add-schedule {
        @include margin(3rem auto);

        form {
            align-items: flex-start;
            display: flex;
            flex-wrap: wrap; }

        .form-group {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;

            @include grid-media($tablet) {
                margin-right: 3rem;
                width: calc(50% - 1.5rem);

                &:nth-child(odd) {
                    margin-right: 0; } }

            &:last-child {
                width: 100%; }

            li {
                text-align: left; } }

        .form-action {
            width: 50%; }

        .border-select,
        .time {
            margin-top: 0; }

        .user-actions {
            max-width: 300px;
            width: 100%;
            @include margin(0 auto);
            @include padding(1rem null); } }

    .schedule-list {
        text-align: left;
        @include margin(3rem auto);

        p {
            text-indent: 1.875rem;
            @include margin(0 null 1.5rem); }

        .schedule-day-list {
            display: flex;
            flex-wrap: wrap; }

        .container-white {
            background-color: $white;
            box-sizing: border-box;
            margin-bottom: 1.875rem;
            width: 100%;

            @include grid-media($tablet) {
                margin-right: 30px;
                width: calc(50% - 15px);

                &:nth-child(2n) {
                    margin-right: 0; } } }
        button {
            margin-top: 1.5rem; } } }
