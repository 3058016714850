.grid-flex-even {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between; }

.grid-flex-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center; }

.grid-flex-row-center {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center; }

.grid-flex-row-top {
    align-content: space-between;
    align-items: flex-start;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center; }

.grid-flex-column {
    align-items: flex-start;
    display: flex;
    flex-flow: column nowrap; }

.grid-flex-column-top {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start; }

.grid-flex-column-end {
    align-items: flex-end;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center; }

.grid-flex-row-start {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start; }

.row-nowrap {
    display: flex;
    flex-flow: row nowrap; }

.row-wrap {
    display: flex;
    flex-flow: row wrap; }

.space-around {
    justify-content: space-around; }

.align-items-center {
    align-items: center; }

.col-xs-2 {
    margin-right: 1.5em;
    width: calc(50% - (1.5em / 2));

    &:nth-child(2n) {
        margin-right: 0px; } }

.col-md-2 {
    @include grid-media($tablet) {
        margin-right: 1.5em;
        width: calc(50% - (1.5em / 2));

        &:nth-child(2n) {
            margin-right: 0; } } }

.address-grid-form {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    label {
        width: 100%; }

    .main-road {
        width: 40%;

        @include grid-media($tablet) {
            width: 30%; } }

    .street-number {
        text-align: center;
        width: 13%;

        @include grid-media($tablet) {
            width: 15%; } }

    .symbol {
        padding: 11px 0;
        text-align: center;
        width: 5%; } }

.creditcard-add-grid {
        justify-content: space-between;
        margin-bottom: 1.5rem; }
