footer {
    background-color: $red; }

.base-footer {
    background-color: $gray-primary;
    color: $white;

    .upper-footer {
        box-sizing: border-box;
        font-size: .875em;
        line-height: 1.5rem;
        text-align: center;
        @include padding(3em null);

        @include grid-media($tablet) {
            display: flex;
            justify-content: space-between;
            line-height: 2rem;
            text-align: left; }

        ul {
            list-style-type: none;
            @include padding(0); }

        .social {
            align-items: center;
            display: flex;
            justify-content: center; }

        .circle-button {
            align-items: center;
            display: flex;
            justify-content: center;
            opacity: .5;
            transition: opacity .4s ease-in-out;
            @include size(40px);

            @include grid-media($tablet) {
                justify-content: flex-start; }

            &:hover {
                opacity: 1; } }

        .social-icon {
            background-color: $white;
            fill: $gray-primary;
            @include border-bottom-radius(50%);
            @include border-top-radius(50%);
            @include padding(4px);
            @include size(16px); }

        .links-col1, .links-col2 {
            display: none;

            @include grid-media($tablet) {
                display: block; }

            a {
                cursor: pointer;
                opacity: .5;
                text-decoration: none;
                transition: opacity .4s ease-in-out;

                &:hover {
                    opacity: 1; } } } }

    .lower-footer {
        background-color: $black;
        font-size: .75em;

        .container {
            box-sizing: border-box;
            text-align: center;
            @include padding(1em);

            @include grid-media($tablet) {
                align-items: center;
                display: flex;
                justify-content: space-between; } }

        .link {
            text-decoration: none; } } }
