.jconfirm-box {
    max-width: 450px; }

.jconfirm-bg {
    background-color: $black;
    opacity: .6; }

.datepicker {
    z-index: 2; }

.jconfirm {
    z-index: 99; }

.jconfirm.jconfirm-gehnios-form {
    .jconfirm-box {
        padding: 0;
        @include border-bottom-radius(5px);
        @include border-top-radius(5px);

        .jconfirm-closeIcon {
            color: $red;
            opacity: 1;
            position: absolute;
            right: 15px;
            top: 17px;
            z-index: 99; }

        .jconfirm-content-pane {
            margin-bottom: 0; } } }

.jconfirm.jconfirm-gehnios-modal {
    .jconfirm-box {
        box-sizing: border-box;
        font-family: 'Open Sans', sans-serif;
        padding: 1.875em;
        @include border-bottom-radius(5px);
        @include border-top-radius(5px);

        .jconfirm-closeIcon {
            color: $red;
            opacity: 1;
            position: absolute;
            right: 15px;
            top: 17px;
            z-index: 99; }

        .jconfirm-title-c {
            align-items: center;
            display: flex;
            flex-direction: column;
            font-size: 1.25em;
            font-weight: 600;
            line-height: 1.5rem;
            padding-bottom: 2rem;
            text-align: center;

            .jconfirm-title {
                padding-bottom: 0; }

            .jconfirm-icon-c {
                @include margin(1rem 0);
                @include padding(0);
                @include size(80px);

                .img-icon {
                    background-repeat: no-repeat;
                    background-size: 100%;
                    display: block;
                    @include size(80px);

                    &_approved {
                        background-image: url(../svg/approved.svg); }

                    &_info {
                        background-image: url(../svg/info.svg); }

                    &_error {
                        background-image: url(../svg/error.svg); }

                    &_loader {
                        animation: rotation 5000ms linear infinite;
                        background-image: url(../svg/loader.svg);
                        @include margin(0 auto);
                        @include size(60px);

                        @keyframes rotation {
                          0% {
                            transform: rotate(0deg); }

                          100% {
                            transform: rotate(360deg); } } } } } }

        .jconfirm-content-pane {
            font-size: 1em;
            line-height: 1.5rem;
            margin-bottom: 0;
            text-align: center; }

        .jconfirm-buttons {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 2rem;
            padding-bottom: 0;

            @include grid-media($tablet) {
                flex-direction: row; }

            button {
                background-color: $gray-primary;
                box-sizing: border-box;
                color: $white;
                font-size: 1rem;
                padding: 1.1875rem .9375rem;
                text-transform: capitalize;
                transition: color .4s ease-in-out, background-color 0.4s ease-in-out, border-color .4s ease-in-out;
                width: 100%;
                @include border-bottom-radius(5px);
                @include border-top-radius(5px);

                @include grid-media($tablet) {
                    width: 50%; }

                &:hover {
                    background-color: $red;
                    color: $white; } } } } }

.modal-add-creditcard {
    .form-group {
        @include grid-media($tablet) {
            width: 45%; } }
    .title {
        text-align: center; }

    .actions {
        text-align: center;

        .btn {
            max-width: 18.75rem; } }

    .payment-partner {
        align-items: center;
        display: flex;
        font-size: .75em;
        justify-content: center;
        padding-top: 1.875rem;

        .brand-logo {
            height: 24px;
            padding-left: .5rem; }

        p {
            @include margin(0); } } }

.modal-no-provider {
    text-align: center;

    .card-icon {
        fill: $blue;
        @include margin(0 auto);
        @include size(50px); }

    h3 {
        border-bottom: 1px solid $gray-tertiary;
        margin-top: 1rem;
        max-width: 80%;
        padding-bottom: 40px;
        @include margin(0 auto);
        @include padding(1rem 0 2rem); }

    p {
        @include margin(2.5rem 0); }

    .btn {
        font-weight: 400;
        margin-top: .5rem;
        max-width: 18.75rem;

        &:first-child {
            margin: 0; } } }

.modal-provider-detail {
    .card {
        color: $gray-primary;

        .card-icon2 {
            fill: $red;
            margin-right: 1.25rem;
            margin-left: 1.25rem;
            @include size(24px);

            &_text {
                align-self: flex-start; } } }

    .profile {
        display: flex;
        flex-wrap: nowrap;
        width: 100%; }

    .profile-info {
        box-sizing: border-box;
        padding-right: 20px;
        width: calc(100% - 60px); }

    p {
        @include margin(0); }

    .provider-type {
        color: $gray-secondary; }

    .name {
        font-weight: 600; }

    .avatar-img {
        background-color: $gray-quaternary;
        background-size: cover;
        border: 2px solid $gray-quaternary;
        box-sizing: border-box;
        display: inline-table;
        margin-right: 1.25rem;
        @include border-bottom-radius(50%);
        @include border-top-radius(50%);
        @include size(40px); }

    .title_xs {
        padding-bottom: 1rem; }

    .resume {
        color: $gray-secondary; }

    .btn {
        max-width: 80%;
        font-weight: 400; }

    .actions {
        margin-top: 2rem;
        text-align: center; } }

.modal-quotation-table {
    table {
        border-collapse: collapse;
        font-size: .75rem;
        width: 100%;

        th {
            border-bottom: 1px solid $red;
            color: $red;
            font-weight: inherit;
            padding-bottom: .5rem;

            &:first-child {
                text-align: left; }

            &:nth-child(2) {
                text-align: center; }

            &:last-child {
                text-align: right; } }

        td {
            border-bottom: 1px solid $gray-quaternary;
            line-height: 1.2rem;
            text-align: left;
            vertical-align: top;
            @include padding(1rem 0);

            &:first-child {
                padding-right: 1rem; }

            &:nth-child(2) {
                text-align: center; }

            &:last-child {
                padding-left: 10px;
                text-align: right; } }

        .total-cell {
            color: $red; } } }

.modal-cancel-service {
    .actions {
        text-align: center;

        .btn {
            max-width: 18.75rem; } }

    .title {
        font-weight: 600;
        margin: 1rem 0 2rem;
        text-align: center; }

    .subtitle {
        font-size: .875rem; } }

.modal-changue-medical-network {
    text-align: center;

    select {
        background-color: $gray-quaternary;
        border: none;
        box-sizing: border-box;
        @include border-bottom-radius(5px);
        @include border-top-radius(5px);
        @include padding(0 1rem); } }
