.landing {
    background-color: $white;

    &.provider-landing {
        .hero {
            background-position: 80%; } }

    h2 {
        font-size: 2em;
        line-height: 1.3em;
        margin: auto 0;

        @include grid-media($tablet) {
            font-size: 3em; } }

    h2 span {
        color: $red; }

    .hero {
        background-position: 65%;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        height: 470px;
        position: relative;

        &:before {
            background-color: $black;
            content: '';
            opacity: .45;
            z-index: 1;
            @include position(absolute, 0 null null 0);
            @include size(100%); }

        .container {
            position: relative;
            width: 1200px;
            z-index: 2; }

        .hero-info {
            margin-left: 1.875em;
            color: $white;
            max-width: 370px;

            @include grid-media($mobile) {
                margin-left: 1.875rem; }

            @include grid-media($tablet) {
                max-width: 570px; }

            .btn_green {
                background-color: $green;

                &:hover {
                    background-color: $gray-primary; } }

            .btn_blue {
                background-color: $blue;

                &:hover {
                    background-color: $gray-primary; } } }

        .btn {
            margin-top: 2em; } }

    .call-to-action {
        background-color: $green;
        box-sizing: border-box;
        color: $white;
        font-weight: 600;
        text-align: center;
        @include padding(4rem 0);

        @include grid-media($tablet) {
            align-items: center;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            @include padding(1.5rem 0); }

        &_contact {
            background-color: $gray-secondary;

            @include grid-media($tablet) {
                font-size: 1.25em;
                @include padding(4rem 0); } }

        &_provider-landing {
            background-color: $blue; }

        p {
            @include margin(0 1.875rem null);

            @include grid-media($tablet) {
                @include margin(1em 1.875rem null 0); } } }

    .card-boxes {
        @include margin(2.5em null);
        @include padding(0 1.875em);

        @include grid-media($tablet) {
            @include margin(6.25em null); }

        h2 {
            font-size: 2em;
            @include margin(5rem 0);

            @include grid-media($tablet) {
                font-size: 2.5em; } }

        .box-container {
            display: flex;
            flex-wrap: wrap;
            margin: 0 auto;
            max-width: 970px; }

        .card {
            background-color: $white;
            box-shadow: 0 0 10px rgba(0,0,0,.1);
            box-sizing: border-box;
            display: flex;
            flex-wrap: nowrap;
            margin-bottom: 1.875em;
            width: 100%;
            @include border-bottom-radius(.3125em);
            @include border-top-radius(.3125em);
            @include padding(1.875em);

            @include grid-media($tablet) {
                margin-right: 30px;
                width: calc(50% - 15px);

                &:nth-child(2n) {
                    margin-right: 0; } } }

        .card-text {
            margin-right: 0;
            width: auto;

            h3 {
                color: $gray-primary;
                font-size: 1em;
                margin-top: 0; }

            p {
                font-size: .875em; } }

        .icon-container {
            border: 2px solid $red;
            box-sizing: border-box;
            display: none;
            margin-right: 1.875em;
            @include border-bottom-radius(50%);
            @include border-top-radius(50%);
            @include size(40px);

            @include grid-media($mobile) {
                display: block; } }

        .card-icon {
            margin-right: 0;
            @include padding(7.5px);
            @include size(20px); } }

    .message-box {
        background-color: $gray-primary;
        box-sizing: border-box;
        @include padding(2.5em null);

        .container {
            display: flex;
            flex-wrap: wrap;
            max-width: 970px;
            @include margin(2.5em null);

            @include grid-media($desktop) {
                justify-content: space-between;
                @include margin(6.25em null); } }

        h2 {
            color: $white;
            font-size: 1.5em;
            text-align: center;
            width: 100%;
            @include padding(0 .5em);
            @include margin(0 auto 1.875em);

            @include grid-media($desktop) {
                font-size: 2.5em;
                padding-right: 1.875rem;
                text-align: left;
                width: 40%;
                @include margin (0); } }

        .card {
            max-width: 470px;
            width: 100%;
            @include margin(0 auto);

            @include grid-media($desktop) {
                margin-right: 0;
                width: 50%; } }

        .container-white {
            box-sizing: border-box;
            @include padding(2.5rem); }

        .card-text {
            width: calc(100% - 60px); }

        h3 {
            color: $gray-primary;
            font-size: 1em;
            font-weight: 400;
            margin-top: 0; }

        p {
            padding-bottom: 1rem; }

        .card-icon {
            display: none;
            @include size(40px);

            @include grid-media($mobile) {
                display: block; } } }

    .grid-benefits {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid $gray-quaternary;

        .benefit-card {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            @include grid-media($desktop-xl) {
                width: 50%; } }

        .benefit-image {
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            @include size(100% 200px);

            @include grid-media($desktop) {
                @include size(50% auto); }

            &_order {
                @include grid-media($desktop) {
                    order: 1; }

                @include grid-media($desktop-xl) {
                    order: 0; } } }

        .card {
            align-self: flex-start;
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            @include padding(3em);

            @include grid-media($desktop) {
                width: 50%; }

            &_order {
                order: initial;

                @include grid-media($desktop-xl) {
                    order: -1; } }

            h3 {
                color: $gray-primary;
                font-size: 1.5em;
                line-height: 2rem;
                margin-top: 0;
                width: calc(100% - 70px); }

            .card-text {
                width: 80%; }

            p {
                font-size: .875ems;

                @include grid-media($mobile) {
                    font-size: 1em; } }

            .card-icon {
                margin-left: 1.875rem;
                @include size(40px); } } }

    .testimonials {
        align-items: center;
        background-color: $gray-quaternary;
        display: flex;
        height: 600px;
        justify-content: center;

        .container-white {
            box-sizing: border-box;
            max-width: 970px;
            text-align: center;
            width: 100%;
            @include padding(1.875rem);

            h3 {
                font-size: 1.5em;
                @include margin(.625rem 0 2.5rem); } }

        .testimony {
            text-align: center;

            h4 {
                margin-top: 0; }

            img {
                background-position: 50%;
                background-repeat: no-repeat;
                background-size: cover;
                box-shadow: 0 0 15px rgba(0,0,0,.3);
                @include border-bottom-radius(50%);
                @include border-top-radius(50%);
                @include margin(0 auto);
                @include size(60px);

                @include grid-media($tablet) {
                    @include size(100px); } }

            .icon-quote {
                width: 40px;
                @include margin(0 auto);
                @include padding(1.5em); }

            p {
                color: $gray-secondary;
                max-width: 570px;
                padding-bottom: .675rem;
                @include margin(0 auto); } }

        .btn-back {
            .stripe {
                transition: background-color 0.4s ease-in-out; } }

        .slick-disabled {
            .stripe {
                background-color: $gray-tertiary; } } }

    .instructive {
        .container {
            flex-wrap: wrap;
            max-width: 970px;
            @include padding(5em null); }

        .hero-text {
            text-align: center;
            width: 100%;
            @include margin(0 auto);
            @include padding(0 1em);

            @include grid-media($tablet) {
                text-align: left;
                width: 35%; }

            h2 {
                font-size: 2em;

                @include grid-media($tablet) {
                    font-size: 3em; } }

            p {
                color: $gray-secondary;
                font-size: 1em;
                margin-bottom: 5rem;

                @include grid-media($tablet) {
                    font-size: 1.5em;
                    margin-bottom: 0; } } }

        .card-margin-bottom {
            margin-bottom: 2.5rem; }

        .card {
            box-shadow: 0 0 10px rgba(0,0,0,.1);
            flex-wrap: wrap;
            width: 100%;
            @include margin(0 1.5em);
            @include padding(2.5em 1.875em);

            @include grid-media($tablet) {
                width: 40%; } }

        .card-icon {
            display: none;
            fill: $green;

            @include grid-media($mobile) {
                display: block; } }

        .card-text {
            line-height: 1.5rem;
            width: calc(100% - 44px);

            h3 {
                color: $gray-primary;
                font-size: 1rem;
                font-weight: 300;
                margin-top: 0; }

            p {
                font-size: .75em;
                @include margin(0); } } } }

