#{$all-text-inputs} {
    appearance: none;
    background-color: transparent;
    border-color: $gray-tertiary;
    border-style: solid;
    border-width: 0 0 1px;
    box-sizing: border-box;
    color: $gray-primary;
    font-size: 1em;
    font-weight: 400;
    height: 2.5em;
    outline: none;
    transition: border-color, .4s ease-in-out;
    width: 100%;
    @include border-top-radius(0);
    @include border-bottom-radius(0);

    @include grid-media($tablet) {
        margin-top: .625em; }

    &:focus {
        border-color: $gray-primary; }

    &::placeholder {
        color: $gray-tertiary;
        opacity: 1; }

    &[readonly] {
        background-color: $gray-quaternary;
        opacity: .7;
        cursor: not-allowed; }

    &.border-input {
        background-color: $white;
        border-width: 1px;
        height: 3.5em;
        padding: 0 1.25em;
        @include border-top-radius(.3125em);
        @include border-bottom-radius(.3125em); }

    &.text-area-comment {
        height: 5em;
        text-align: left; }

    &[type=number]::-webkit-inner-spin-button,
    &[type=number]::-webkit-outer-spin-button {
        appearance: none;
        margin: 0; } }

select {
    appearance: none;
    background-color: transparent;
    background-image: url(../svg/arrow-select.svg);
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: 2.5em .5625em;
    border-color: $gray-tertiary;
    border-style: solid;
    border-width: 0 0 1px;
    box-sizing: border-box;
    padding-right: 2.5em;
    color: $gray-primary;
    font-size: 1em;
    font-weight: 400;
    height: 2.5em;
    outline: none;
    padding: 0 2.5em 0 0;
    width: 100%;
    @include border-top-radius(0);
    @include border-bottom-radius(0);

    @include grid-media($tablet) {
        margin-top: .625em; }

    &::-ms-expand {
        display: none; }

    &:focus {
        border-color: $gray-primary; }

    &.border-select {
        background-color: $white;
        border-width: 1px;
        padding: 0 2.5em 0 1.25em;
        @include border-top-radius(.3125em);
        @include border-bottom-radius(.3125em); } }

label {
    box-sizing: border-box;

    &.required {
        padding-left: .625em;
        position: relative;

        &::before {
            color: $gray-primary;
            content: '*';
            font-size: .75rem;
            left: 0;
            position: absolute; } }

    &.actual-content {
        color: $gray-secondary;
        @include margin(1.25em null); } }

textarea {
    height: 8.125em;
    line-height: 1.25em;
    margin-top: 1em;
    padding-bottom: 1em;
    resize: vertical; }

.input-file {
    background-color: $white;
    border-color: $gray-tertiary;
    border-style: solid;
    border-width: 0 0 1px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 1em;
    overflow: hidden;
    position: relative;
    width: 100%;

    @include grid-media($tablet) {
        margin-top: .625em; }

    &:hover {
        border-color: $gray-primary;

        .input-file__bottom {
            fill: $gray-primary; } }

    &__container {
        color: $gray-tertiary;
        cursor: pointer;
        margin-bottom: 0;
        @include clearfix; }

    &__text {
        box-sizing: border-box;
        float: left;
        font-size: 1rem;
        overflow: hidden;
        padding: .6875em 1em .6875em 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(100% - 2.125em);

        span {
            color: $gray-tertiary; } }

    &__bottom {
        box-sizing: border-box;
        fill: $gray-tertiary;
        float: right;
        margin: .5em 0;
        max-width: none;
        @include size(1.5em); }

    &__input {
        opacity: 0;
        overflow: hidden;
        z-index: -1;
        @include position(absolute, null null 0 0);
        @include size(2.5em 1.4375em); } }

.form-group {
    margin-bottom: 1.875em;
    width: 100%;

    label {
        display: block;
        font-size: 0.75em;
        line-height: 1.5em;

        &.error {
            color: $red;
            font-size: .75em;
            line-height: 1.75em;
            margin: 1em 0 0; } }

    .error-list, .errorlist {
        color: $red;
        font-size: .75em;
        list-style: none;
        margin: 1em 0 0;
        padding: 0;

        li {
            line-height: 1.75em; } }

    .help-text {
        color: $gray-secondary;
        font-size: 0.75em;
        margin-top: 1em;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                line-height: 1.75em; } } }

    &_less-margin-bottom {
        margin-bottom: 1em; }

    &_no-margin-bottom {
        margin-bottom: 0; } }

.checkbox {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 1em;
    position: relative;

    label {
        font-size: 0.875em;
        line-height: inherit;
        width: calc(100% - 2rem); }

    &__container {
        margin-right: 0.75rem;
        position: relative;
        @include size(1.25em); }

    &__input {
        opacity: 0;
        z-index: 1;
        @include position(absolute, null null 0 3); }

    &__block {
        border: 2px solid $gray-secondary;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;
        z-index: 2;
        @include border-bottom-radius(.3125em);
        @include border-top-radius(.3125em);
        @include size(1.25em);

        &:after {
            background-color: $red;
            content: '';
            display: block;
            margin: auto;
            opacity: 0;
            transition: opacity .4s ease-in-out;
            @include border-bottom-radius(3px);
            @include border-top-radius(3px);
            @include position(absolute, 0 0 0 0);
            @include size(.5em); }

        &.active:after {
            opacity: 1; } } }

.radio {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 1em;
    position: relative;

    label {
        font-size: 0.875em;
        line-height: 1.5em;
        width: calc(100% - 2rem); }

    &__container {
        margin-right: .75rem;
        position: relative;
        @include size(1.25em); }

    &__input {
        opacity: 0;
        z-index: 1;
        @include position(absolute, null null 0 3px); }

    &__block {
        border: 2px solid $gray-secondary;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;
        z-index: 2;
        @include border-bottom-radius(50%);
        @include border-top-radius(50%);
        @include size(1.25em);

        &:after {
            background-color: $red;
            content: '';
            display: block;
            margin: auto;
            opacity: 0;
            transition: opacity .4s ease-in-out;
            @include border-bottom-radius(50%);
            @include border-top-radius(50%);
            @include position(absolute, 0 0 0 0);
            @include size(.5em); }

        &.active:after {
            opacity: 1; } } }

.landing-contact-form {
    .container {
        @include grid-media($tablet) {
            max-width: 770px; } }

    h4 {
        font-weight: 400;
        text-align: center;
        margin-bottom: 3rem; }

    .form-group {
        width: 100%;

        @include grid-media($tablet) {
            margin-right: 3rem;
            width: calc(50% - 1.5rem);

            &:nth-child(2n - 1) {
                margin-right: 0; }

            &:last-child {
                margin-right: 0;
                width: 100%; } }

        &:last-child {
            margin-bottom: 0; } } }
